
:root {
  --dark-less:        #292e4b;
  --dark:             #212641;
  --dark-more:        #1A1E35;
  --dark-black:       #000000;
  --dark-white:       #FFFFFF;
  --dark-blue:        #235fac;
  --dark-highlight1:  #155aa3;
  --dark-highlight2:  #FAA652;
  --dark-highlight3: #40416b;
  --dark-highlight4: #00E1FF;
  --dark-highlight5: #8c5a29;
}

/*
.dark .contenu{
  background: var(--dark);
}
.dark .noir{
  color: var(--dark-white);
}
.dark select.noir{
  color: var(--dark-black);
}
.dark .bg-blanc {
  background-color: var(--dark-more);
}
.dark .bg-gris-ecf1f2 {
  background-color: var(--dark-less);
}
.dark .bg-bleu-CBF9FF {
  background-color: var(--dark-blue);
}
.dark .bg-82bcff {
  background: var(--dark-highlight3)!important;
}
.dark .bleu-00E1FF {
  color: var(--dark-highlight2)!important;
}
.dark .navigation .bleu {
  color: var(--dark-highlight2)!important;
}

.dark .colorMention {
  background: var(--dark-highlight5);
}

.dark .header{
  background: var(--dark-more);
}
.dark .header input{
  background: transparent;
  color: var(--dark-white);
}
.dark .header input:checked + .slider {
  background-color: var(--dark-highlight2);
}

.dark .menu{
  background: var(--dark-less);
}
.dark .menu a:hover {
  color: var(--dark-highlight5)
}

.dark .principal.btn {
  background-color:  var(--dark-highlight2);
  border-color: var(--dark-highlight2);
  color: var(--dark-more);
}
.dark .btn-bleu-plein {
  border: solid 2px var(--dark-highlight2);
  background-color: var(--dark-highlight2);
  color: var(--dark-more);
}
.dark .btn-bleu-vide {
  border: solid 2px var(--dark-highlight2);
  color: var(--dark-highlight2);
}
.dark .btn-rouge-vide {
  background: red;
  color: var(--dark-white);
}
.dark .btn-rouge-vide:hover {
  border: solid 2px var(--dark-highlight2);
  background: var(--dark-highlight2);
  color: var(--dark-more);
}

.dark .row.ligne {
  background-color: var(--dark-more);
}
.dark .row.ligne.tachecachee{
  background-color: var(--dark-blue)!important;
}
.dark .intutulesTab {
  background-color: var(--dark-blue);
}
.dark .row-vue-client {
  background-color: var(--dark-highlight3);
}
.dark .tachecachee{
  background-color: var(--dark-blue)!important;
}

.dark .cke_inner {
  background: var(--dark-less);
}
.dark .cke_inner .cke_top,
.dark .cke_inner .cke_contents body,
.dark .cke_inner .cke_toolbox,
.dark .cke_inner .cke_contents,
.dark .cke_inner .cke_wysiwyg_div,
.dark .cke_inner .cke_wysiwyg_frame,
.dark .cke_inner .cke_bottom
{
  background: var(--dark-less);
  color: var(--dark-white);
}
.dark .cke_button_label,
.dark .cke_combo_text {
  color: var(--dark-white);
}

.dark .modal-content {
  background: var(--dark-less);
}
.dark .notification:hover {
  background: var(--dark-blue);
}
.dark .modal-content .bleu {
  color: var(--dark-highlight2);
}

.dark .contenuMessage a,
.dark .contenuMessage .bleu,
.dark .discussionClient .bleu,
.dark .description .bleu,
.dark .description a{
  color: var(--dark-highlight2);
}

.dark .navigation .description {
  color: var(--dark-highlight2);
  border-bottom: solid 1px var(--dark-highlight2);
}

.dark form[name=tache_edit] .photoDeProfil :checked + label img {
  border-color: var(--dark-highlight2);
}

.dark #tache_edit_facturation input:checked + label {
  background-color: var(--dark-highlight2);
}

.dark #formFiltreTache #filtre_facturation input:checked + p, .dark #formFiltreTache #filtre_statut input:checked + p {
  background-color: var(--dark-highlight2);
}

.dark .filtreParPersonne input:checked + label img {
  border-color: var(--dark-highlight2);
}

.dark #formFiltreWiki #filtre_statut input:checked + p {
  background-color: var(--dark-highlight2);
}

.dark .CTClient {
  background-color: var(--dark-more);
}
.dark .bar:before {
  background: var(--dark-more);
}

*/